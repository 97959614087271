function IsEmail(email) {
    var regex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if(!regex.test(email)) {
      return false;
    }else{
      return true;
    }
}
  
$(document).ready(function () {
    $('body').on('submit', '.email-form', function (e) {
        e.preventDefault();
        $('.section-contact .response').remove();
        var $form = $(this);
        var hasError = false;
        $form.find('.error-message').remove();
        $form.find('#first_name').removeClass('error-con');
        if (!$.trim($form.find('#first_name').val())) {
            $form.find('#first_name').addClass('error-con');
            $form.find('#first_name').parents('.form-group').append('<span class="error-message">Field is required</span>')
            hasError = true;
        }

        $form.find('#email').removeClass('error-con');
        if (!$.trim($form.find('#email').val())) {
            $form.find('#email').addClass('error-con');
            $form.find('#email').parents('.form-group').append('<span class="error-message">Field is required</span>')
            hasError = true;
        }
        else if(!IsEmail($form.find('#email').val()) ) {
            $form.find('#email').addClass('error-con');
            $form.find('#email').parents('.form-group').append('<span class="error-message">Email is invalid</span>')
            hasError = true;
        }

        $form.find('#message').removeClass('error-con');
        if (!$.trim($form.find('#message').val())) {
            $form.find('#message').addClass('error-con');
            $form.find('#message').parents('.form-group').append('<span class="error-message">Field is required</span>')
            hasError = true;
        }

        if(!hasError)
        {
            $form.find('.btn-send').prop('disabled', true);
            $form.find('.btn-send').text('Please wait..');
            $.post($form.attr('action'), $form.serialize(), function (result) {
                $form.find('.btn-send').prop('disabled', false);
                $form.find('.btn-send').text('submit');
                var result = JSON.parse(result);
                var type = result.type;
                $('.section-contact .section-btn-block').after('<div class="response ' + result.type + '">' + result.text + '</div>');

                if (result.type == 'done') {
                    $form.find('.form-control').val('');
                }
            });
        }    
    });

    $('body').on('click', '.scrollto', function () {
        $('html, body').animate({
            scrollTop: $( $(this).attr('href') ).offset().top
        }, 2000);
        $('#first_name').focus();
    });

    $('body').on('submit', '.email-form', function (e) {
        
    });
}); 
